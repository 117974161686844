<template>
  <div id="order-view-actions-spec-delivred-excluding-accept">
    <OrderViewActionAccept v-if="!isQuoteOwner && quoteSelected.quote.paymentTerm !== 'on_contract'" btn-icon="CreditCardIcon" :btn-text="$tc('payment.title', 1)" />
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'OrderViewActionsSpecDelivredExcludingAccept',

  components: {
    OrderViewActionAccept: () => import('@/views/order/OrderViewActionAccept.vue'),
  },

  computed: {
    ...mapState('quote', ['isQuoteOwner', 'quoteSelected']),
  },
}
</script>
